<template>
  <form v-on:submit.prevent>
    <b-loading
      v-model="loading"
      is-full-page
      class="is-primary"
    ></b-loading>
    <div class="level">
      <b-button
        v-if="canBeSavedAsDraft"
        type="is-blue"
        size="is-small"
        icon-right="save"
        outlined
        @click="saveAsDraft"
      >
        {{ $t('form.content.saveAsDraft') }}
      </b-button>
      <b-button
        v-else-if="canBeUpdatedDraft"
        type="is-blue"
        size="is-small"
        icon-right="save"
        outlined
        @click="updateDraft"
      >
        {{ $t('form.content.updateDraft') }}
      </b-button>
      <div v-else></div>
      <div>
        <span>{{ $t('form.content.needHelp') }}&nbsp;</span>
        <router-link
          :to="{ name: 'contact', query: { quit: 'warn' } }"
          class="has-text-weight-bold"
        >
          {{ $t('form.content.contactUs') }}
        </router-link>
      </div>
    </div>
    <component
      v-if="!areStepsEmpty"
      :is="`page-${currentFormStep.component}`"
      :key="instanceId"
    ></component>
  </form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import api from '@/api';
import { getReqFormData } from '@/utils/helper';

export default {
  name: 'FormContent',
  components: {
    PageBeneficiary: () => import('@/components/page/req/PageBeneficiary'),
    PageConfirmation: () => import('@/components/page/PageConfirmation'),
    PageCompanyName: () => import('@/components/page/PageCompanyName'),
    PageDirector: () => import('@/components/page/PageDirector'),
    PageFounder: () => import('@/components/page/PageFounder'),
    PageHeadOffice: () => import('@/components/page/PageHeadOffice'),
    PageNeq: () => import('@/components/page/req/PageNeq'),
    PageOfficer: () => import('@/components/page/PageOfficer'),
    PageOptions: () => import('@/components/page/PageOptions'),
    PageOther: () => import('@/components/page/PageOther'),
    PageOtherIndividual: () => import('@/components/page/req/PageOtherIndividual'),
    PagePayment: () => import('@/components/page/PagePayment'),
    PagePreface: () => import('@/components/page/PagePreface'),
    PageProfessionalOrder: () => import('@/components/page/PageProfessionalOrder'),
    PageReqConfirmation: () => import('@/components/page/req/PageReqConfirmation'),
    PageReqDirector: () => import('@/components/page/req/PageReqDirector'),
    PageShare: () => import('@/components/page/PageShare'),
    PageShareholder: () => import('@/components/page/PageShareholder'),
    PageValidateInfo: () => import('@/components/page/req/PageValidateInfo')
  },
  data () {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState('form', [
      'currentStep',
      'currentForm',
      'currentUpdator',
      'directorFiles',
      'model',
      'steps',
      'instanceId'
    ]),
    ...mapGetters('form', [
      'areStepsEmpty',
      'supportsDraft'
    ]),
    canBeSavedAsDraft () {
      return this.supportsDraft && this.currentStep > 0 && !this.draftId;
    },
    canBeUpdatedDraft () {
      return this.supportsDraft && this.currentStep > 0 && !!this.draftId;
    },
    currentFormStep () {
      if (this.currentStep < 0) {
        return { component: 'preface' };
      } else {
        return this.steps[this.currentStep];
      }
    },
    draftId () {
      return this.$route.query.draftId;
    }
  },
  methods: {
    saveAsDraft () {
      this.loading = true;

      this.currentUpdator()
        .then(() => api.saveAsDraft(getReqFormData(this.$i18n.locale, this.model, this.directorFiles)))
        .then(() => {
          this.loading = false;
          this.$router.push({ name: 'professionalaccess-req', query: { quit: 'force' } });
        })
        .catch(() => {
          this.loading = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: this.$t('form.content.errorSavingDraft'),
            type: 'is-danger'
          });
        });
    },
    updateDraft () {
      this.loading = true;

      this.currentUpdator()
        .then(() => api.updateDraft(this.draftId, getReqFormData(this.$i18n.locale, this.model, this.directorFiles)))
        .then(() => {
          this.loading = false;
          this.$router.push({ name: 'professionalaccess-req', query: { quit: 'force' } });
        })
        .catch(() => {
          this.loading = false;
          this.$buefy.toast.open({
            duration: 5000,
            message: this.$t('form.content.errorSavingDraft'),
            type: 'is-danger'
          });
        });
    }
  }
};
</script>
