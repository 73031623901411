<template>
  <div class="form-view">
    <div class="columns is-gapless is-multiline">
      <div class="column is-one-quarter-widescreen is-one-third-desktop is-full-tablet">
        <form-steps></form-steps>
      </div>
      <div class="column is-three-quarters-widescreen is-two-thirds-desktop is-full-tablet">
        <div class="form-view-content-navigation">
          <form-content></form-content>
          <form-navigation></form-navigation>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FormContent from './FormContent'
  import FormNavigation from './FormNavigation'
  import FormSteps from './FormSteps'

  export default {
    name: 'FormView',
    components: { FormNavigation, FormContent, FormSteps }
  }
</script>
