<template>
  <div class="form-steps">
    <div class="form-steps__header">
      <figure class="image form-steps__image">
        <router-link :to="{ name: returnPage, query: { quit: 'warn' } }">
          <img src="/corpoexpress.png">
        </router-link>
      </figure>
      <div class="form-steps__product">
        <p class="form-steps__product-title">{{ $t(`form.package.${currentForm}.label`) }}</p>
        <p class="form-steps__product-description">{{ $t(`form.package.${currentForm}.description`) }}</p>
      </div>
    </div>
    <b-steps
      v-if="!areStepsEmpty && currentStep >= 0"
      v-model="currentStepProxy"
      label-position="right"
      size="large"
      :vertical="vertical"
      :has-navigation="false"
      destroy-on-hide
      mobile-mode="minimalist"
      type="is-blue"
      class="b-steps--form-steps"
    >
      <b-step-item
        v-for="(step, stepIndex) in steps"
        :key="stepIndex"
        :label="$t('page.label.' + step.component)"
        :step="stepIndex + 1"
        :icon="isStepDone(stepIndex) ? 'check' : undefined"
      >
      </b-step-item>
    </b-steps>
  </div>
</template>

<script>
import auth from '@/mixins/auth';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'FormSteps',
  mixins: [auth],
  computed: {
    ...mapState('form', [
      'currentForm',
      'currentStep',
      'model',
      'steps'
    ]),
    ...mapGetters('form', [
      'areStepsEmpty'
    ]),
    currentStepProxy: {
      get () {
        return this.currentStep;
      },
      set (step) {
        this.setStep(step);
      }
    },
    returnPage () {
      switch (this.$route.name) {
        case 'incorporation-form': {
          if (this.hasIncorporationAccess) {
            return 'professionalaccess-incorporation';
          } else {
            return 'incorporation';
          }
        }
        case 'req-form': return 'professionalaccess-req';
        default: return 'home';
      }
    }
  },
  data () {
    return {
      vertical: true,
      mediaQueryList: null
    };
  },
  mounted () {
    this.mediaQueryList = window.matchMedia('(max-width: 1023px)');
    this.mqlListener(this.mediaQueryList);
    this.mediaQueryList.addListener(this.mqlListener);
    this.updateSteps();
  },
  beforeDestroy () {
    this.mediaQueryList.removeListener(this.mqlListener);
  },
  methods: {
    ...mapActions('form', [
      'setStep',
      'updateSteps'
    ]),
    isStepDone (stepIndex) {
      return this.currentStep > stepIndex;
    },
    mqlListener (mql) {
      if (mql.matches) {
        this.vertical = false;
      } else {
        this.vertical = true;
      }
    }
  },
  watch: {
    currentStepProxy () {
      this.updateSteps();
    }
  }
};
</script>
