<template>
  <div class="level form-navigation">
    <div
        v-if="!shouldExitEarly && !shouldUpgradeForm"
        class="level-left level-item"
    >
      <router-link v-if="isLastStep" :to="{ name: 'home', query: { quit: 'warn' } }">
        <b-button
            class="has-text-primary pr-45"
            type="is-text"
            icon-right="times"
        >
          {{ $t('form.navigation.cancel') }}
        </b-button>
      </router-link>
      <b-button
          v-else-if="!isFirstStep"
          type="is-text"
          class="form-navigation__previous"
          @click="previousStep"
      >
        {{ $t('form.navigation.previous') }}
      </b-button>
    </div>
    <div
        v-if="!shouldExitEarly && !shouldUpgradeForm"
        class="level-right level-item"
    >
      <b-button
          v-if="!isLastStep"
          :disabled="isNextStepDisabled"
          type="is-blue"
          icon-right="chevron-right"
          @click="nextStep"
      >
        {{ $t(`form.navigation.${isConfirmationStep ? 'confirm' : 'next'}`) }}
      </b-button>
    </div>
    <div
        v-if="shouldExitEarly"
        class="level-item"
    >
      <b-button
          type="is-primary"
          tag="router-link"
          :to="{ name: 'home', query: { quit: 'force' }}"
      >
        {{ $t('form.navigation.exit') }}
      </b-button>
    </div>
    <div
        v-else-if="shouldUpgradeForm"
        class="level-item"
    >
      <b-button
          type="is-primary"
          @click="upgradeForm"
      >
        {{ $t('form.navigation.upgrade') }} (+{{ getPriceDifference }}$)
      </b-button>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { CA_PRICE_DIFF, QC_PRICE_DIFF } from '@/utils/constants'

  export default {
    computed: {
      ...mapState('form', [
        'model',
        'currentForm'
      ]),
      ...mapGetters('form', [
        'isConfirmationStep',
        'isFirstStep',
        'isLastStep',
        'isNextStepDisabled',
        'shouldExitEarly',
        'shouldUpgradeForm'
      ]),
      getPriceDifference () {
        return 'QC_NUM' === this.currentForm ? QC_PRICE_DIFF : CA_PRICE_DIFF
      }
    },
    methods: {
      ...mapActions('form', [
        'nextStep',
        'previousStep',
        'upgradeForm'
      ])
    }
  }
</script>
