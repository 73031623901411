<template>
  <div>
    <b-loading
      v-model="loading"
      is-full-page
      class="is-primary"
    >
    </b-loading>
    <form-view v-if="!loading && currentForm"></form-view>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import auth from '@/mixins/auth';
import FormView from '@/components/form/FormView';

export default {
  name: 'IncorporationForm',
  components: { FormView },
  mixins: [auth],
  beforeMount () {
    if (!this.currentForm) {
      this.$router.push({ name: 'incorporation' });
    } else {
      this.loading = false;
    }
  },
  mounted () {
    window.addEventListener('beforeunload', this.onBeforeUnload);
    this.setHasIncorporationAccess(this.hasIncorporationAccess);
  },
  destroyed () {
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  },
  data () {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState('form', [
      'currentForm',
      'currentStep',
      'isNavigatingToPaypal'
    ]),
    ...mapGetters('form', [
      'isFirstStep',
      'isLastStep'
    ])
  },
  methods: {
    ...mapActions('form', [
      'clearForm',
      'previousStep',
      'setIsNavigatingToPaypal',
      'setHasIncorporationAccess'
    ]),
    confirmFormLeave (next) {
      this.$buefy.dialog.confirm({
        message: this.$t('form.warning.message'),
        cancelText: this.$t('form.warning.cancel'),
        confirmText: this.$t('form.warning.confirm'),
        focusOn: 'cancel',
        onConfirm: () => {
          next();
          this.clearForm();
        },
        onCancel: () => {
          next(false);
        }
      });
    },
    onBeforeUnload (e) {
      if (!this.isNavigatingToPaypal) {
        this.setIsNavigatingToPaypal(false);
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.currentForm || to.query?.quit === 'force') {
      next();
      this.clearForm();
    } else if (to.query?.quit === 'warn' || this.isFirstStep) {
      this.confirmFormLeave(next);
    } else if (!this.isFirstStep) {
      this.previousStep();
      next(false);
    } else {
      next();
    }
  }
};
</script>
